










import { Component, Vue } from "vue-property-decorator";

import Hero from "@/components/homePage/Hero.vue";
import SectionBenefits from "@/components/homePage/SectionBenefits.vue";
import SectionProcess from "@/components/homePage/SectionProcess.vue";
import SectionNosotros from "@/components/homePage/SectionNosotros.vue";
import SectionFaq from "@/components/homePage/SectionFaq.vue";
import QueEs from "@/components/homePage/QueEs.vue";
@Component({
  components: {
    Hero,
    QueEs,
    SectionBenefits,
    SectionProcess,
    SectionNosotros,
    SectionFaq
  }
})
export default class HomePage extends Vue {}
