import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

const theme = {
  primary: '#303f9f',
  secondary: '#90caf9',
  accent: '#2F4858',
  error: '#FF5252',
  info: '#86BBD8',
  success: '#a5d6a7',
  warning: '#FFC107'
};
export default new Vuetify({
  theme: {
    themes: {
      light: theme
    },
    options: {
      customProperties: true
    }
  }
});
