import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import './plugins/baseLoader';
import VueAnalytics from 'vue-analytics';
import Hotjar from 'vue-hotjar';

Vue.config.productionTip = false;

Vue.use(VueAnalytics, {
  id: 'UA-180732662-1',
  router
});

Vue.use(Hotjar, {
  id: '2064585' // Hotjar Site ID
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');
