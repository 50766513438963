import { MutationTree, GetterTree } from 'vuex';
import { State } from '@/store/interfaces/Formulary';
import FormularyTypes from '@/store/types/FormularyTypes';

const namespaced = true;

const state: State = {
  form: null
};

const getters: GetterTree<State, any> = {
  [FormularyTypes.getters.GET_FORM]: state => state.form
};

const mutations: MutationTree<State> = {
  [FormularyTypes.mutations.SET_FORM]: (state, form) => (state.form = form)
};

export const formularyStore = {
  namespaced,
  state,
  mutations,
  getters
};
