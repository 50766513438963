






























































import { Component, Vue } from "vue-property-decorator";
@Component
export default class SectionFaq extends Vue {
  faq_selected = 3;
}
