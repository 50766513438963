import { MutationTree, GetterTree, ActionTree } from 'vuex';
import { State } from '@/store/interfaces/Product';
import ProductTypes from '@/store/types/ProductTypes';

import ProductsRepository from '@/repository/modules/products.repository';

const namespaced = true;

const state: State = {
  product: null
};

const getters: GetterTree<State, any> = {
  [ProductTypes.getters.GET_PRODUCT]: state => state.product,
  [ProductTypes.getters.GET_PRICE]: state =>
    `${((state.product?.price as number) / 100).toFixed(2)}${state.product?.sign}`.replace('.', ',')
};

const mutations: MutationTree<State> = {
  [ProductTypes.mutations.SET_PRODUCT]: (state, product) => (state.product = product)
};

const actions: ActionTree<State, any> = {
  [ProductTypes.actions.FETCH_PRODUCT]: async ({ commit }) => {
    await ProductsRepository.getProducts()
      .then(({ data }) => {
        commit(ProductTypes.mutations.SET_PRODUCT, data.products[0]);
      })
      .catch(error => {
        console.log(error);
      });
  }
};

export const productStore = {
  namespaced,
  state,
  mutations,
  actions,
  getters
};
