import API from '../repository';

import { FormularioSolicitudImpl } from '@/models/Formulario.model';
import { UserImpl } from '@/models/User.model';
import { PaymentImpl, PayoutResponseImpl, VerificationPaymentImpl } from '@/models/Payout.model';

const RESOURCE = '/user';

export default {
  async nuevaSolicitud(form: FormularioSolicitudImpl) {
    return API.post<{ user: UserImpl }>(`${RESOURCE}/nueva-solicitud`, { ...form });
  },

  async pagoSolicitud(payment: PaymentImpl) {
    return API.post<{ payout: PayoutResponseImpl }>(`${RESOURCE}/pago-solicitud`, {
      ...payment
    });
  },

  async verificarPagoSolicitud(verificationPayment: VerificationPaymentImpl) {
    return API.put<{ payout: PayoutResponseImpl }>(`${RESOURCE}/verificar-pago-solicitud`, {
      ...verificationPayment
    });
  }
};
