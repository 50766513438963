import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import HomePage from '../views/HomePage.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
    meta: {
      title: 'Servicio Gestión y Solicitud Vida Laboral | serviciovidalaboral.com'
    },
    children: [
      {
        path: '/formulario',
        name: 'Formulario',
        meta: {
          title: 'Formulario de solicitud | serviciovidalaboral.com'
        },
        component: () => import(/*webpackChunkName: "Formulario"*/ '../views/Formulario.vue')
      }
    ]
  },
  {
    path: '/privacidad',
    name: 'Privacidad',
    meta: {
      title: 'Privacidad | serviciovidalaboral.com'
    },
    component: () => import(/* webpackChunkName: "privacidad" */ '../views/Privacidad.vue')
  },
  {
    path: '/cookies',
    name: 'Cookies',
    meta: {
      title: 'Cookies | serviciovidalaboral.com'
    },
    component: () => import(/* webpackChunkName: "cookies" */ '../views/Cookies.vue')
  },
  {
    path: '/terminos-y-condiciones',
    name: 'Terminos',
    meta: {
      title: 'Terminos y condiciones | serviciovidalaboral.com'
    },
    component: () => import(/* webpackChunkName: "cookies" */ '../views/Terminos.vue')
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
        offset: { x: 0, y: 150 }
      };
    } else {
      return { x: 0, y: 0 };
    }
  }
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});

export default router;
