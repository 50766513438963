








import { Component, Vue } from "vue-property-decorator";
import { Action, Mutation } from "vuex-class";
import ProductTypes from "@/store/types/ProductTypes";

import NavBar from "./components/structure/navBar.vue";
import NavFooter from "./components/structure/navFooter.vue";

@Component({
  components: {
    NavBar,
    NavFooter
  }
})
export default class App extends Vue {
  @Action(`productStore/FETCH_PRODUCT`) fetchProducts: any;
  beforeMount() {
    this.fetchProducts();
  }
}
