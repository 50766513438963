export default {
  getters: {
    GET_ID_USER: 'GET_ID_USER',
    GET_ERRORS: 'GET_ERRORS',
    GET_STEP: 'GET_STEP'
  },
  mutations: {
    SET_USER: 'SET_USER',
    SET_ERRORS: 'SET_ERRORS',
    SET_STEP: 'SET_STEP',
    RESET_PROCESS: 'RESET_PROCESS'
  },
  actions: {
    SUBMIT_FORM: 'SUBMIT_FORM',
    FINALICE_PROCESS: 'FINALICE_PROCESS'
  }
};
