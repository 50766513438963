import { ActionTree, GetterTree, MutationTree } from 'vuex';

import UserRepository from '@/repository/modules/user.repository';

import { State } from '../interfaces/Solicitud';

import { FormularioSolicitudImpl } from '@/models/Formulario.model';
import SolicitudTypes from '../types/SolicitudTypes';
import { UserImpl } from '@/models/User.model';

const namespaced = true;

const state: State = {
  user: null,
  error: null,
  step: 1
};

const getters: GetterTree<State, any> = {
  [SolicitudTypes.getters.GET_ID_USER]: state => state.user?._id,
  [SolicitudTypes.getters.GET_ERRORS]: state => state.error,
  [SolicitudTypes.getters.GET_STEP]: state => state.step
};

const mutations: MutationTree<State> = {
  [SolicitudTypes.mutations.SET_USER]: (state, user: UserImpl) => (state.user = user),
  [SolicitudTypes.mutations.RESET_PROCESS]: state => {
    state.user = null;
    state.error = null;
  },
  [SolicitudTypes.mutations.SET_STEP]: (state, step: number) => (state.step = step)
};

const actions: ActionTree<State, any> = {
  [SolicitudTypes.actions.SUBMIT_FORM]: async ({ commit }, form: FormularioSolicitudImpl) => {
    UserRepository.nuevaSolicitud(form)
      .then(({ data }) => {
        commit(SolicitudTypes.mutations.SET_USER, data.user);
        commit(SolicitudTypes.mutations.SET_STEP, 2);
      })
      .catch(error => {
        console.log(error);
      });
  },
  [SolicitudTypes.actions.FINALICE_PROCESS]: async ({ commit }) => {
    commit(SolicitudTypes.mutations.RESET_PROCESS);
    commit(SolicitudTypes.mutations.SET_STEP, 3);
  }
};

export const solicitudStore = {
  namespaced,
  state,
  mutations,
  actions,
  getters
};
