










import { Component, Vue } from "vue-property-decorator";
@Component
export default class MaterialOverlays extends Vue {
  get windowWidth() {
    const win = window,
      doc = document,
      docElem = doc.documentElement,
      body = doc.getElementsByTagName("body")[0],
      x = win.innerWidth || docElem.clientWidth || body.clientWidth,
      y = win.innerHeight || docElem.clientHeight || body.clientHeight;
    console.log(x);
    return x < 420;
  }
}
