import Vue from 'vue';
import Vuex from 'vuex';

import { productStore } from '@/store/modules/Products.store';
import { solicitudStore } from '@/store/modules/Solicitud.store';
import { formularyStore } from '@/store/modules/Formulary.store';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    productStore,
    solicitudStore,
    formularyStore
  }
});
