




















import { Component, Prop, Vue } from "vue-property-decorator";
@Component
export default class InputCard extends Vue {
  @Prop(String) readonly label!: string;
  @Prop(String) readonly icon!: string;
  @Prop(Boolean) readonly isActive: boolean = false;

  get classVinput(): string {
    if (this.isActive) {
      return "v-input--is-focused primary--text";
    } else {
      return "";
    }
  }

  get classLabelColor(): string {
    if (this.isActive) {
      return "primary--text";
    } else {
      return "";
    }
  }
}
