import API from '../repository';
import { ProductImpl } from '@/models/Product.model';

const RESOURCE = '/products';

export default {
  async getProducts() {
    return API.get<{ products: ProductImpl[] }>(`${RESOURCE}`);
  }
};
