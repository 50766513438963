import axios, { AxiosResponse } from 'axios';

const Api = axios.create({
  baseURL: process.env.VUE_APP_API,
  timeout: 36000,
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json'
  }
});

// Request interceptor and inject token bearer
Api.interceptors.request.use(
  async config => {
    config.headers = {
      ...config.headers
      //Authorization: 'Bearer ' + store.getters['userAuthStore/USERTOKENFIREBASE'],
    };
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export default Api;
