export default {
  getters: {
    GET_PRODUCT: 'GET_PRODUCT',
    GET_PRICE: 'GET_PRICE'
  },
  mutations: {
    SET_PRODUCT: 'SET_PRODUCT'
  },
  actions: {
    FETCH_PRODUCT: 'FETCH_PRODUCT'
  }
};
